import React from 'react'
import { Link } from 'gatsby'
import LocalizedLink from './LocalizedLink';

const Aside = ({ siteTitle }) => (

    <aside className="side-navigation-wrapper enter-right" data-no-scrollbar data-animation="push-in" >
        <div className="side-navigation-scroll-pane" >
            <div className="side-navigation-inner" >
                <div className="side-navigation-header" >
                    <div className="navigation-hide side-nav-hide" >
                        <a href="#">
                            <span className="icon-cancel medium"></span>
                        </a >
                    </div >
                </div >
                <nav className="side-navigation" >
                    <ul>
                        <li >
                            <LocalizedLink to="/">home</LocalizedLink>
                        </li>
                        <li >
                            <LocalizedLink to="/studio/">lo studio</LocalizedLink>
                        </li>
                        <li >
                            <LocalizedLink to="/settori/">i settori</LocalizedLink>
                        </li>
                        <li >
                            <LocalizedLink to="/team/">il team</LocalizedLink>
                        </li>
                        <li >
                            <LocalizedLink to="/pubblicazioni/">pubblicazioni</LocalizedLink>
                        </li>
                        <li >
                            <LocalizedLink to="/news/">news</LocalizedLink>
                        </li>
                        <li >
                            <LocalizedLink to="/contatti/">contatti</LocalizedLink>
                        </li>
                    </ul>
                </nav >
            </div >
        </div >
    </aside >

)

export default Aside
