import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import '../css/core.min.css'
import '../css/skin.css'
import Footer from './footer';
import Aside from './aside';
import $ from 'jquery'
import { IntlProvider, addLocaleData } from 'react-intl'

// Locale data
import itData from 'react-intl/locale-data/it'
import enData from 'react-intl/locale-data/en'
import deData from 'react-intl/locale-data/de'

// Messages
import it from '../i18n/it.json'
import en from '../i18n/en.json'
import de from '../i18n/de.json'

const messages = { it, en, de }

addLocaleData([...itData, ...enData, ...deData])

const topFunction = () => {
  $('body,html').animate({
    scrollTop: 0
  }, 500);
}

const Layout = ({ locale, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <>
          <Aside />
          <div className="wrapper reveal-side-navigation">
            <div className="wrapper-inner">
              <Header siteTitle={data.site.siteMetadata.title} />
              <button onClick={topFunction} id="myBtn" ></button>
              <main>
                {children}
              </main>
              <Footer />
            </div>
          </div>
        </>
      </IntlProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
