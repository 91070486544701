import React from 'react'
import { Link } from 'gatsby'
import LocalizedLink from './LocalizedLink';

const Footer = () => (
    <footer className="footer" >
        <div className="footer-top" >
            <div className="row flex" >
                <div className="column width-4" >
                    <div className="widget" >
                        <LocalizedLink to="/">
                            <img data-aos = "fade-up" data-aos-duration = "1000" src={require('../images/img/logo.png')} />
                        </LocalizedLink>
                    </div >
                </div >
                <div className="column width-3 offset-2 none" >
                    <div className="widget" >
                        <ul>
                            <li data-aos = "fade-up">
                                <LocalizedLink to="/studio/">lo studio</LocalizedLink>
                            </li>
                            <li data-aos = "fade-up" data-aos-duration = "500">
                                <LocalizedLink to="/settori/">i settori</LocalizedLink>
                            </li>
                            <li data-aos = "fade-up" data-aos-duration = "900">
                                <LocalizedLink to="/team/">il team</LocalizedLink>
                            </li>
                            <li data-aos = "fade-up" data-aos-duration = "1000">
                                <LocalizedLink to="/pubblicazioni/">pubblicazioni</LocalizedLink>
                            </li>
                            <li data-aos = "fade-up" data-aos-duration = "1300">
                                <LocalizedLink to="/news/">news</LocalizedLink>
                            </li>
                            <li data-aos = "fade-up" data-aos-duration = "1500">
                                <LocalizedLink to="/contatti/">contatti</LocalizedLink>
                            </li>
                        </ul>
                    </div >
                </div >
                <div className="column width-3 all-width" >
                    <div className="widget" >
                        <p data-aos = "fade-up" data-aos-duration = "500">Via Liruti, 12/3<br />33100 Udine (UD)<br />Italy<br />Tel. +39 0432 507253<br />Fax. +39 0432 508857<br /><a href="mailto:segreteria@studiolegalecfs.com">segreteria@studiolegalecfs.com</a></p>
                        <p data-aos = "fade-up" data-aos-duration = "1000">Via Valdirivo, 42<br />34122 Trieste (TS)<br />Italy<br />Tel. +39 040 2476430<br />Fax +39 040 2476430<br /><a href="mailto:segreteria@studiolegalecfs.com">segreteria@studiolegalecfs.com</a></p>
                    </div >
                </div >
            </div >
        </div >

        <div className="row" >
            <hr style={{ opacity: 0.5 }} />
        </div >

        <div className="footer-bottom" >
            <div className="row" >
                <div className="column width-12" >
                    <p className="copyright" >
                        &copy; Studio Legale Cirio Francescon Stella & Partners | P.I. 02448180303 | <a href="/files/cookie.pdf" target="_blank">Cookie policy</a> | <a href="/files/informativa.pdf" target="_blank">Privacy</a> | <a href="https://agenziaunidea.it/" target="_blank">Credits</a>
                    </p >
                </div >
            </div >
        </div >
    </footer >
)

export default Footer
