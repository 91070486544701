module.exports = {
    it: {
        path: 'it',
        locale: 'Italian',
        default: true
    }
    // en: {
    //     path: 'en',
    //     locale: 'English'
    // },
    // de: {
    //     path: 'de',
    //     locale: 'German'
    // }
}
