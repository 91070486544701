import React from 'react'
import { Link } from 'gatsby'
import LocalizedLink from './LocalizedLink';
import '../css/select.css'


const changeLanguage =
  () => {
    let lang = document.querySelector('#changeLanguage').value
    localStorage.setItem('lang', lang)
    window.location.href = `/${lang == 'it' ? '' : lang}`
  }

const Header = () => (
  <header className="header header-fixed header-fixed-on-mobile header-transparent header-animated" data-bkg-threshold="100" data-compact-threshold="100" >
    <div className="header-inner" >
      <div className="row nav-bar" >
        <div className="column width-12 nav-bar-inner" >
          <div className="logo">
            <div className="logo-inner" >
              <LocalizedLink to="/"><img src='/images/logo-2x.png' alt="Logo" /></LocalizedLink>
              <LocalizedLink to="/"><img src='/images/logo-2x.png' alt="Logo" /></LocalizedLink>
            </div >
          </div >
          <nav className="navigation nav-block secondary-navigation nav-right" >
            <ul>
              <li>
                <a href="#" className="navigation-show side-nav-show nav-icon">
                  <span className="icon-menu"></span>
                </a>
              </li >
            </ul >
          </nav >
          <nav className="navigation nav-block primary-navigation nav-right" >
            <ul>
              <li>
                <LocalizedLink to="/studio/" className="lower" id="studio">lo studio</LocalizedLink>
              </li>
              <li >
                <LocalizedLink to="/settori/" className="lower" id="settori">i settori</LocalizedLink>
              </li >
              <li >
                <LocalizedLink to="/team/" className="lower" id="team">il team</LocalizedLink>
              </li >
              <li >
                <LocalizedLink to="/pubblicazioni/" className="lower" id="pubblicazioni">pubblicazioni</LocalizedLink>
              </li >
              <li >
                <LocalizedLink to="/news/" className="lower" id="news">news</LocalizedLink>
              </li >
              <li >
                <LocalizedLink to="/contatti/" className="lower" id="contatti">contatti</LocalizedLink>
              </li >
              <li style={{ display: 'none' }}>
                <LocalizedLink to="/grazie/" className="lower" id="grazie">grazie</LocalizedLink>
              </li >
              <li >
                <select class="selectLanguage" id="changeLanguage" onChange={changeLanguage}>
                  <option  value="it">IT</option>
                  <option  value="en">EN</option>
                  <option  value="de">DE</option>
                </select>
              </li>
            </ul >

          </nav >
        </div >
      </div >
    </div >
  </header >
)

export default Header
